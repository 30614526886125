const config = {
	api: {
		hostUrl: 'wss://api.next.nercia.se/',
		webResourcePath: 'https://api.next.nercia.se/api/'
	},
	auth: {
		loginUrl: 'https://api.next.nercia.se/auth',
		logoutUrl: 'https://api.next.nercia.se/auth/logout',
		authenticateUrl: 'https://api.next.nercia.se/auth?noredirect',
	},
	file: {
		uploadUrl: 'https://api.next.nercia.se/file/'
	},
	sentry: {
		dsn: 'https://c8b9dbbd6c85a22b3b5545f2653bd196@o4505799358021632.ingest.sentry.io/4505799365033984',
		environment: 'production',
		tracesSampleRate: 0.1,
	}
};

export default config;
